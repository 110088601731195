.admins {
    .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label {
            font-weight: bold;
        }

        input {
            height: 40px;
        }
    }
    .footer {
        margin-top: 24px;
        display: flex;
        justify-content: center;

        .ant-btn {
            height: 40px;
            font-weight: bold;
            color: white;
        }

        .cancel {
            background-color: #EC4859;
            margin-right: 40px;
        }

        .success {
            background-color: #1B7BB9;
        }
    }
}