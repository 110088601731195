.product {
    display: flex;
    justify-content: center;
    .ant-row {
        width: 100%;
    }
    .item {
        margin: 5px 0;
        padding: 5px 10px;
    }
    .item:nth-child(even) {
        background: #F7F7F7;
    }
    .head-title {
        font-weight: bold;
        font-size: 20px;
        margin-top: 10px;
        line-height: 4;
    }
    .title {
        font-weight: bold;
        font-size: 15px;
    }

    .value {
        padding-left: 10px;
    }
    
    img {
        width: 250px;
        height: 250px;
    }
}