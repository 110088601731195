.ant-layout {
    min-height: 100vh;
    .ant-layout-header {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        font-weight: bold;
        font-size: 16px;
        padding: 0 18px;
        .logout {
            cursor: pointer;
        }
    }
    .ant-layout-content {
        margin: 0 16px;
        .container {
            padding: 24px;
            background: #fff;
            min-height: 360px;
        }
    }
    .logo {
        text-align: center;
        color: white;
        font-size: 18px;
        padding: 16px 0;
        font-weight: bold;
    }
    .ant-layout-sider-collapsed {
        .logo {
            img {
                width: 70px;
            }
        }
    }
    .ant-layout-footer {
        text-align: center;
    }
}