.admins-list {
    button.add {
        text-align: right;
        margin-bottom: 15px;
        font-weight: bold;
        color: white;
        background-color: #1B7BB9;
        height: 40px;
    }
    .table-container {
        .actions {
            display: flex;
            button:last-of-type {
                margin-left: 10px;
            }
        }
    }
}