.user {
    display: flex;
    justify-content: center;
    .item {
        margin: 5px 0;
        padding: 5px 10px;
    }
    .item:nth-child(even) {
        background: #F7F7F7;
    }
    .head-title {
        font-weight: bold;
        font-size: 20px;
        margin-top: 10px;
        line-height: 2;
        padding-left: 10px;
    }
    .title {
        font-weight: bold;
        font-size: 15px;
    }
}