.login-form {
  position: absolute;
  background: white;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 27%;
  max-width: 400px;
  width: 400px;
  padding: 0 30px;
  border-radius: 3px;

  .title {
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item-label {
    line-height: 30px;
  }
  .login-form-forgot {
    float: right;
  }
  .login-form-button {
    width: 100%;
    background-color: #1890ff;
    margin-top: 10px;
  }
  .ant-input {
    height: 40px;
    border: 1px solid #ccc;
    &:focus {
      border-color: #1890ff;
    }
  }
  button {
    height: 40px;
  }
  .bottom-links {
    margin-top: 10px;
    a {
      color: #1890ff;
    }
  }
}
