.table-container {
    .ant-pagination  {
        text-align: center;
        margin-top: 40px;
    }
    .actions {
        .ant-btn {
            color: white;
            padding: 0 10px;
        }
        .btn-edit {
            background: #1B7BB9;
        }
        .btn-delete {
            background: #EC4859;
        }
    }
}