.login-page {
    position: relative;
    height: 100vh;
    background: #001529;

    label {
        font-weight: bold;
    }

    .login-form-button {
        font-weight: bold;
    }
}